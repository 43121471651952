import React from 'react';
import ReactWOW from 'react-wow';
import { Container, Row, Col, Label, Button, Form } from 'reactstrap';
import { ErrorMessage, Field, Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as Yup from 'yup';

// Static Assets
import SendIcon from '@material-ui/icons/Send';

interface Props {}

interface DetailObj {
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface State {
  detailObject: DetailObj;
  captchaToken: string;
}

const contactUsFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter Name'),
  email: Yup.string()
    .required('Please enter Email Address')
    .email('Please enter a valid Email Address'),
  subject: Yup.string().required('Please enter Subject'),
  message: Yup.string().required('Please enter Message'),
});

class SectionContactUs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      captchaToken: '',
      detailObject: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
    };
  }

  handleVerifyToken = (token: string) => {
    this.setState({ captchaToken: token });
  };

  handleSubmit = async (values: DetailObj, resetForm: any) => {
    try {
      let formData = new FormData();
      formData.append('captchaToken', this.state.captchaToken);
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('subject', values.subject);
      formData.append('message', values.message);
      const contactApiResponse: any = await axios({
        method: 'post',
        url: 'mail.php',
        baseURL: '/',
        data: formData,
      });
      if (contactApiResponse.data.success) {
        resetForm();
        toast.success('Thanks for filling out the form. Email is sent successfully.', {
          autoClose: 2000,
        });
        resetForm();
      } else {
        toast.error(contactApiResponse.data.data.message, {
          autoClose: 2000,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        autoClose: 2000,
      });
    }
  };

  render() {
    return (
      <>
        <section
          className="section--contact section--contact-sm"
          data-bg={'white'}
          id="ContactUs"
        >
          <Container fluid={false}>
            <Row className="mb-5">
              <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
                <div className="s--title text-center">
                  <span>CONTACT</span>
                  <h5>Stay in Touch</h5>
                  <h4>Let's work together</h4>
                  <div className="page-head--divider"></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={9} lg={8} className="col-centered">
                <ReactWOW animation="fadeInDown">
                  <Formik
                    initialValues={this.state.detailObject}
                    onSubmit={(values, { resetForm }) => {
                      this.handleSubmit(values, resetForm);
                    }}
                    validationSchema={contactUsFormValidationSchema}
                  >
                    {({ handleSubmit, errors, touched }) => {
                      return (
                        <Form
                          onSubmit={handleSubmit}
                          className="form-horizontal"
                          autoComplete="off"
                        >
                          <Row className="form-group ">
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className={`m-mb-20 ${
                                touched.name && errors.name ? 'form-group--has-error' : ''
                              }`}
                            >
                              <Label for="name">
                                Name <span className="req">*</span>
                              </Label>
                              <Field
                                name="name"
                                type="text"
                                className={`form-control ${
                                  touched.name && errors.name ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="help-block"
                              />
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className={`${
                                touched.email && errors.email
                                  ? 'form-group--has-error'
                                  : ''
                              }`}
                            >
                              <Label for="email">
                                Email <span className="req">*</span>
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={`form-control ${
                                  touched.email && errors.email ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="help-block"
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className={`${
                                touched.subject && errors.subject
                                  ? 'form-group--has-error'
                                  : ''
                              }`}
                            >
                              <Label for="subject">
                                Subject <span className="req">*</span>
                              </Label>
                              <Field
                                name="subject"
                                type="text"
                                className={`form-control ${
                                  touched.subject && errors.subject ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="subject"
                                component="span"
                                className="help-block"
                              />
                            </Col>
                          </Row>

                          <Row className="form-group">
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className={`${
                                touched.message && errors.message
                                  ? 'form-group--has-error'
                                  : ''
                              }`}
                            >
                              <Label for="message">
                                Message <span className="req">*</span>
                              </Label>
                              <Field
                                name="message"
                                rows="5"
                                component="textarea"
                                className={`big form-control ${
                                  touched.message && errors.message ? 'is-invalid' : ''
                                }`}
                              />
                              <ErrorMessage
                                name="message"
                                component="span"
                                className="help-block"
                              />
                            </Col>
                          </Row>

                          <Button color="primary" type="submit">
                            <SendIcon fontSize="small" className="mr-2" />
                            Submit
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </ReactWOW>
                <GoogleReCaptcha onVerify={this.handleVerifyToken} />
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionContactUs;
