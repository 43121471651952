import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import AppRoute from './components/AppRoute';

// Import Layout
import Layout from './layout/Layout';

// Home Page
import Home from './views/Home';

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ||
        '6Lceq9wZAAAAAPvwREd6yR4c5i0DDD5gcLMMkuDZ'
      }
    >
      <BrowserRouter>
        <Switch>
          <AppRoute path="/" component={Home} layout={Layout} />
        </Switch>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  );
}

export default App;
