import React from 'react';
import ReactWOW from 'react-wow';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Static Assets
import SystemsIntegration from '../assets/images/Systems-Integration@2x.png';
import ExternalSystems from '../assets/images/icons/External-Systems.svg';
import SocialMedia from '../assets/images/icons/Social-Media.svg';

import { Container, Row, Col } from 'reactstrap';

interface SectionSystemIntegrationProps {}
interface SectionSystemIntegrationState {}

class SectionSystemIntegration extends React.Component<
  SectionSystemIntegrationProps,
  SectionSystemIntegrationState
> {
  constructor(props: SectionSystemIntegrationProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="section--webSolutions section--webSolutions-sm pt-0"
          data-bg={'gray'}
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={12} lg={12} xl={6}>
                <LazyLoadImage
                  effect="blur"
                  width={'100%'}
                  alt="System Integration"
                  src={SystemsIntegration}
                  srcSet={SystemsIntegration}
                />
              </Col>

              <Col xs={12} md={12} lg={12} xl={6} className="text-left">
                <div className="section--webSolutions__inner section--webSolutions__inner-sm">
                  <ReactWOW animation="fadeInUp">
                    <div className="page-head page-head-sm start u-mb--40 u-mb--40-sm">
                      <h1 className="u-heading--exlarge u-heading--exlarge-sm mb-3">
                        System <strong>Integration</strong>
                      </h1>
                      <p className="fs--17 fs--17-sm">
                        We provide services to integrate application with external systems
                        to cater client requirements.
                      </p>
                    </div>
                  </ReactWOW>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <ReactWOW animation="slideInLeft">
                        <div className="webSolutions--blocks m-mb-5">
                          <LazyLoadImage
                            effect="blur"
                            alt="Social Media"
                            src={SocialMedia}
                            className="size--80 size--80-sm"
                          />
                          <h4>Social Media</h4>
                          <p className="mb-2">Integration with social media services:</p>
                          <ul className="styled-bullet">
                            <li>Facebook</li>
                            <li>Twitter</li>
                            <li>Instagram</li>
                            <li>Others</li>
                          </ul>
                        </div>
                      </ReactWOW>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <ReactWOW animation="slideInRight">
                        <div className="webSolutions--blocks">
                          <LazyLoadImage
                            effect="blur"
                            alt="External Systems"
                            src={ExternalSystems}
                            className="size--80 size--80-sm"
                          />
                          <h4>External Systems</h4>
                          <p className="mb-2">
                            Integration with external service providers:
                          </p>
                          <ul className="styled-bullet">
                            <li>Email retrieval and processing</li>
                            <li>SMS sending and retrieval</li>
                            <li>Email content publishing and campaigns</li>
                            <li>Others</li>
                          </ul>
                        </div>
                      </ReactWOW>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionSystemIntegration;
