import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

// Menu
import Menu from '../Menu/Menu';

//static assets
import logo from '../../../assets/images/logo.png';

interface HeaderProps extends RouteComponentProps {
  sticky?: boolean;
}

interface HeaderState {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      setDropdownOpen: false,
      color: '',
    };
  }

  private navsidebarToggle = React.createRef<HTMLButtonElement>();

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  openNavSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.navsidebarToggle.current?.classList.toggle('toggled');
  };
  closeNavSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.navsidebarToggle.current?.classList.toggle('toggled');
  };

  render() {
    return (
      <div>
        <div className="menu--overlay" onClick={() => this.closeNavSidebar()} />
        <div
          className={`c-header c-header-sm ${this.props.sticky ? `header--sticky` : ``}`}
        >
          <Container fluid={true} className="container-fluid container-fluid-sm">
            <div className="navbar-toggle d-block d-lg-none d-xl-none">
              <button
                type="button"
                id="jsCloseSidebar"
                ref={this.navsidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openNavSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>

            <Row>
              <Col md="12" className="">
                <div className="c-header__main-info c-header__main-info-sm">
                  <div className="c-header__main-info--logo c-header__main-info--logo-md">
                    <a href="/">
                      <img src={logo} className="image-fluid app--logo" alt="Ideoris" />
                    </a>
                  </div>
                  <Menu {...this.props} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
