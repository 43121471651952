import React from 'react';
import ReactWOW from 'react-wow';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Static Assets
import ModernSolutions from '../assets/images/icons/Modern-Solutions.svg';
import SystemsLifetime from '../assets/images/icons/Systems-Lifetime.svg';
import EnterpriseSolutions from '../assets/images/Enterprise-Solutions.svg';

import { Container, Row, Col } from 'reactstrap';

interface SectionEnterpriseSolutionProps {}
interface SectionEnterpriseSolutionState {}

class SectionEnterpriseSolution extends React.Component<
  SectionEnterpriseSolutionProps,
  SectionEnterpriseSolutionState
> {
  constructor(props: SectionEnterpriseSolutionProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="section--webSolutions section--webSolutions-sm"
          data-bg={'white'}
        >
          <Container fluid={false}>
            <Row className="d-flex align-items-center">
              <Col xs={12} md={12} lg={12} xl={6} className="text-left">
                <div className="section--webSolutions__inner section--webSolutions__inner-sm pt-0">
                  <ReactWOW animation="fadeInUp">
                    <div className="page-head page-head-sm start u-mb--40 u-mb--40-sm">
                      <h1 className="u-heading--exlarge u-heading--exlarge-sm mb-3">
                        Enterprise <strong>Solutions</strong>
                      </h1>
                      <p className="fs--17 fs--17-sm">
                        We provide solutions that are highly scalable and well suited for
                        complex and long running systems.
                      </p>
                    </div>
                  </ReactWOW>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <ReactWOW animation="slideInLeft">
                        <div className="webSolutions--blocks m-mb-5">
                          <LazyLoadImage
                            effect="blur"
                            alt="Modern Solutions"
                            src={ModernSolutions}
                            className="size--80 size--80-sm"
                          />
                          <h4>Modern Solutions</h4>
                          <p className="mb-2">
                            Cutting edge solutions that are well architected:
                          </p>
                          <ul className="styled-bullet">
                            <li>Saas - Single solution for multiple websites</li>
                            <li>Cloud and AWS services</li>
                            <li>Multi tier architecture</li>
                          </ul>
                        </div>
                      </ReactWOW>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <ReactWOW animation="slideInRight">
                        <div className="webSolutions--blocks m-mb-5">
                          <LazyLoadImage
                            effect="blur"
                            alt="Systems Lifetime"
                            src={SystemsLifetime}
                            className="size--80 size--80-sm"
                          />
                          <h4>Systems Lifetime</h4>
                          <p className="mb-2">
                            We provide solutions that are modern and highly scalable.
                          </p>
                          <ul className="styled-bullet">
                            <li>Scalable designs</li>
                            <li>High availability and low down time</li>
                            <li>High performance</li>
                          </ul>
                        </div>
                      </ReactWOW>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={12} md={12} lg={12} xl={6}>
                <LazyLoadImage
                  effect="blur"
                  width={'100%'}
                  className="img-fluid img-responsive"
                  alt="Enterprise Solutions"
                  src={EnterpriseSolutions}
                  srcSet={EnterpriseSolutions}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionEnterpriseSolution;
