import React from 'react';
import ReactWOW from 'react-wow';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import CSS

// Static Assets
import Client01 from '../assets/images/clients/client-1.png';
import Client02 from '../assets/images/clients/client-2.png';
import Client03 from '../assets/images/clients/client-3.png';
import Client04 from '../assets/images/clients/client-4.png';
import Client05 from '../assets/images/clients/client-5.png';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

interface SectionClientsProps {}
interface SectionClientsState {}

class SectionClients extends React.Component<SectionClientsProps, SectionClientsState> {
  constructor(props: SectionClientsProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="section--clients section--clients-sm"
          data-bg={'gray'}
          id="Clients"
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} className="col-centered">
                <Row className="u-flexed u-align-center">
                  <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                    <ReactWOW animation="fadeInLeft">
                      <div className="mb-5">
                        <div className="page-head page-head-sm center u-mb--15">
                          <h1 className="u-heading--exlarge u-heading--exlarge-sm u-mb--0">
                            Trusted by <strong>many clients</strong>
                          </h1>
                        </div>
                      </div>
                    </ReactWOW>

                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={11}
                        className="col-centered text-center m-mb-5"
                      >
                        <ReactWOW animation="fadeInRight">
                          <ul className="client--lists">
                            <li className="client--lists-items">
                              <figure className="image-block-wrapper">
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Cooperate"
                                  src={Client01}
                                  className="img-fluid"
                                />
                              </figure>
                            </li>
                            <li className="client--lists-items">
                              <figure className="image-block-wrapper">
                                <LazyLoadImage
                                  effect="blur"
                                  alt="OWL CDS"
                                  src={Client02}
                                  className="img-fluid"
                                />
                              </figure>
                            </li>
                            <li className="client--lists-items">
                              <figure className="image-block-wrapper">
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Verali Shoes"
                                  src={Client03}
                                  className="img-fluid"
                                />
                              </figure>
                            </li>
                            <li className="client--lists-items">
                              <figure className="image-block-wrapper">
                                <LazyLoadImage
                                  effect="blur"
                                  alt="SheppNews"
                                  src={Client04}
                                  className="img-fluid"
                                />
                              </figure>
                            </li>
                            <li className="client--lists-items">
                              <figure className="image-block-wrapper">
                                <LazyLoadImage
                                  effect="blur"
                                  alt="McPherson Media Group"
                                  src={Client05}
                                  className="img-fluid"
                                />
                              </figure>
                            </li>
                          </ul>
                        </ReactWOW>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionClients;
