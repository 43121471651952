import React, { FunctionComponent } from 'react';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Static Include
import Header from './includes/Header/Header';
import MobileMenu from './includes/Menu/MobileMenu';
import Footer from './includes/Footer/Footer';
import useSticky from '../hooks/useSticky';
import Blank from '../views/Blank';

interface LayoutProps extends RouteComponentProps {
  children?: React.ReactElement;
  exact?: boolean;
  path?: string;
}

interface LayoutState {}

const Layout: FunctionComponent<LayoutProps> = (props) => {
  let dataBg = 'white';
  if (
    matchPath(props.match.path, { path: '/' }) ||
    matchPath(props.match.path, { path: '/' })
  ) {
    dataBg = 'white';
  }

  const { isSticky, element } = useSticky();

  return (
    <>
      <div className="wrapper wrapper-full-page" data-bg={dataBg}>
        <div className="navSidebar">
          <MobileMenu {...props} />
        </div>
        <div className="main-panel">
          <Header {...props} sticky={isSticky} />
          <Blank element={element} />
          {props.children}

          <Footer {...props} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default withRouter(Layout);
