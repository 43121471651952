import React from 'react';
import ReactWOW from 'react-wow';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry from 'react-masonry-css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Import CSS

// Static Assets
import Portfolio01 from '../assets/images/portfolio/portfolio-01.jpg';
import Portfolio02 from '../assets/images/portfolio/portfolio-02.jpg';
import Portfolio03 from '../assets/images/portfolio/portfolio-03.jpg';
import Portfolio04 from '../assets/images/portfolio/portfolio-04.jpg';
import Portfolio05 from '../assets/images/portfolio/portfolio-05.jpg';
// import Portfolio06 from '../assets/images/portfolio/portfolio-06.jpg';
import Portfolio07 from '../assets/images/portfolio/portfolio-07.jpg';

import { Container, Row, Col } from 'reactstrap';

interface SectionPorfolioProps {}
interface SectionPorfolioState {
  isOpen: boolean;
  isLightboxOpen: boolean;
  portfolioSrc: string;
}

const breakpointColumnsObj = {
  default: 3,
  991: 2,
  500: 1,
};

class SectionPorfolio extends React.Component<
  SectionPorfolioProps,
  SectionPorfolioState
> {
  constructor(props: SectionPorfolioProps) {
    super(props);
    this.state = {
      isOpen: false,
      isLightboxOpen: false,
      portfolioSrc: Portfolio01,
    };
  }
  myRef = React.createRef();

  render() {
    const { isLightboxOpen } = this.state;
    return (
      <>
        <section
          className="section--portfolio section--portfolio-sm"
          data-bg={'white'}
          id="Portfolio"
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="text-center col-centered">
                <ReactWOW animation="fadeInUp">
                  <div className="mb-5">
                    <div className="page-head page-head-sm center u-mb--15">
                      <h3 className="text-grey fs--18 mb-1">
                        Available for freelance projects
                      </h3>
                      <h1 className="u-heading--large u-heading--large-sm u-mb--0">
                        <strong>Do you have designing project? Let’s talk.</strong>
                      </h1>
                      <div className="page-head--divider"></div>
                    </div>
                  </div>
                </ReactWOW>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={11} lg={11} className="col-centered">
                <ReactWOW animation="fadeInDown">
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    <div>
                      <LazyLoadImage
                        effect="blur"
                        width={'100%'}
                        className="img-fluid img-responsive"
                        alt="Portfolio 1"
                        src={Portfolio01}
                        srcSet={Portfolio01}
                      />

                      <div className="overlay">
                        <div className="info--content">
                          <a
                            href="http://www.cooperateplatform.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4>Cooperate</h4>
                          </a>
                          <p>SaaS, Design &amp; Build</p>
                        </div>
                        <div className="know-more">
                          <button
                            onClick={() =>
                              this.setState({
                                portfolioSrc: Portfolio01,
                                isLightboxOpen: true,
                              })
                            }
                            className="opens"
                            data-fancybox="gallery"
                            title="Zoom In"
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <LazyLoadImage
                        effect="blur"
                        width={'100%'}
                        className="img-fluid img-responsive"
                        alt="Portfolio 2"
                        src={Portfolio02}
                        srcSet={Portfolio02}
                      />
                      <div className="overlay">
                        <div className="info--content">
                          <a
                            href="http://www.veralishoes.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4>Verali Shoes</h4>
                          </a>
                          <p>Development</p>
                        </div>
                        <div className="know-more">
                          <button
                            onClick={() =>
                              this.setState({
                                portfolioSrc: Portfolio02,
                                isLightboxOpen: true,
                              })
                            }
                            className="opens"
                            data-fancybox="gallery"
                            title="Zoom In"
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <LazyLoadImage
                        effect="blur"
                        width={'100%'}
                        className="img-fluid img-responsive"
                        alt="Portfolio 3"
                        src={Portfolio03}
                        srcSet={Portfolio03}
                      />
                      <div className="overlay">
                        <div className="info--content">
                          <a
                            href="http://www.mmg.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4>McPherson Media Group</h4>
                          </a>
                          <p>Media Website</p>
                        </div>
                        <div className="know-more">
                          <button
                            onClick={() =>
                              this.setState({
                                portfolioSrc: Portfolio03,
                                isLightboxOpen: true,
                              })
                            }
                            className="opens"
                            data-fancybox="gallery"
                            title="Zoom In"
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <LazyLoadImage
                        effect="blur"
                        width={'100%'}
                        className="img-fluid img-responsive"
                        alt="Portfolio 4"
                        src={Portfolio04}
                        srcSet={Portfolio04}
                      />
                      <div className="overlay">
                        <div className="info--content">
                          <a
                            href="http://www.lojac.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4>LOJAC CIVIL</h4>
                          </a>
                          <p>Design &amp; Development, Hosting</p>
                        </div>
                        <div className="know-more">
                          <button
                            onClick={() =>
                              this.setState({
                                portfolioSrc: Portfolio04,
                                isLightboxOpen: true,
                              })
                            }
                            className="opens"
                            data-fancybox="gallery"
                            title="Zoom In"
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <LazyLoadImage
                        effect="blur"
                        width={'100%'}
                        className="img-fluid img-responsive"
                        alt="Portfolio 7"
                        src={Portfolio07}
                        srcSet={Portfolio07}
                      />
                      <div className="overlay">
                        <div className="info--content">
                          <a
                            href="http://www.owldcds.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4>OWLCDS</h4>
                          </a>
                          <p>SaaS, React</p>
                        </div>
                        <div className="know-more">
                          <button
                            onClick={() =>
                              this.setState({
                                portfolioSrc: Portfolio07,
                                isLightboxOpen: true,
                              })
                            }
                            className="opens"
                            data-fancybox="gallery"
                            title="Zoom In"
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div>
                      <LazyLoadImage
                        effect="blur"
                        width={'100%'}
                        className="img-fluid img-responsive"
                        alt="Portfolio 5"
                        src={Portfolio05}
                        srcSet={Portfolio05}
                      />
                      <div className="overlay">
                        <div className="info--content">
                          <a
                            href="http://www.sheppnews.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4>SheppNews</h4>
                          </a>
                          <p>Design &amp; Development</p>
                        </div>
                        <div className="know-more">
                          <button
                            onClick={() =>
                              this.setState({
                                portfolioSrc: Portfolio05,
                                isLightboxOpen: true,
                              })
                            }
                            className="opens"
                            data-fancybox="gallery"
                            title="Zoom In"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </Masonry>
                </ReactWOW>
              </Col>
            </Row>
          </Container>
        </section>
        {isLightboxOpen && (
          <Lightbox
            mainSrc={this.state.portfolioSrc}
            onCloseRequest={() => this.setState({ isLightboxOpen: false })}
          />
        )}
      </>
    );
  }
}

export default SectionPorfolio;
