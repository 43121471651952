import React from 'react';

// Static Sections

interface Props {
  element: any;
}

class Blank extends React.Component<Props, {}> {
  render() {
    return (
      <>
        <div ref={this.props.element} style={{ marginTop: '1px' }}></div>
      </>
    );
  }
}

export default Blank;
