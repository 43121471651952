import React from 'react';
import ReactWOW from 'react-wow';
import { Parallax } from 'react-parallax';

// Static Assets

import { Container, Row, Col } from 'reactstrap';

interface SectionMissioStatementProps {}
interface SectionMissioStatementState {}

class SectionMissioStatement extends React.Component<
  SectionMissioStatementProps,
  SectionMissioStatementState
> {
  constructor(props: SectionMissioStatementProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Parallax
          strength={500}
          bgImage={require('../assets/images/Parallax-BG.svg')}
          bgImageAlt="Parallax"
        >
          <section
            className="section--mission section--mission-sm"
            data-bg={'blue'}
            id="AboutUs"
          >
            <Container fluid={false}>
              <Row>
                <Col xs={12} md={9} lg={9} className="col-centered">
                  <ReactWOW animation="fadeInUp">
                    <div className="page-head page-head-sm center u-mb--15">
                      <h1 className="u-heading--exlarge u-heading--exlarge-sm u-mb--0">
                        Mission <strong>Statement</strong>
                      </h1>
                      <div className="page-head--divider light"></div>
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="fadeInDown">
                    <div className="">
                      <p className="text-center mb-0 quote quote-sm">
                        Provide solutions and right guidance for designing and
                        implementing complex systems. Maintaining client satisfaction and
                        timely servicing requests is our top priority. Stick to our
                        purpose of honesty and transparency while maintaining our attitude
                        to learn and grow.
                      </p>
                    </div>
                  </ReactWOW>
                </Col>
              </Row>
            </Container>
          </section>
        </Parallax>
      </>
    );
  }
}

export default SectionMissioStatement;
