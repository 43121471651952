import React, { FunctionComponent } from 'react';

import { Route, RouteProps } from 'react-router';
import Layout from '../layout/Layout';

interface AppRouteProps extends RouteProps {
  component: new (props: any) => React.Component;
  layout?: new (props: any) => React.Component;
}

const AppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  layout: ComponentLayout = Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ComponentLayout {...props}>
          <Component {...props} />
        </ComponentLayout>
      )}
    />
  );
};

export default AppRoute;
