import React from 'react';
import ReactWOW from 'react-wow';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

// Static Assets
import WebSolutions from '../assets/images/WebSolutions@2x.png';
import OurServices from '../assets/images/icons/Our-Services.svg';
import ResponsiveWebsites from '../assets/images/icons/Responsive-Websites.svg';

interface SectionWebSolutionsProps {}
interface SectionWebSolutionsState {}

//const srcSet = '/static/media/WebSolutions@2x.abb2c5eb.png';

class SectionWebSolutions extends React.Component<
  SectionWebSolutionsProps,
  SectionWebSolutionsState
> {
  constructor(props: SectionWebSolutionsProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="section--webSolutions section--webSolutions-sm pt-0"
          data-bg={'gray'}
        >
          <Container fluid={false}>
            <Row className="d-flex align-items-center">
              <Col xs={12} md={12} lg={12} xl={6}>
                <LazyLoadImage
                  effect="blur"
                  alt="Web Solutions"
                  src={WebSolutions}
                  srcSet={WebSolutions}
                />
              </Col>

              <Col xs={12} md={12} lg={12} xl={6} className="text-left">
                <div className="section--webSolutions__inner section--webSolutions__inner-sm">
                  <ReactWOW animation="fadeInUp">
                    <div className="page-head page-head-sm start u-mb--40 u-mb--40-sm">
                      <h1 className="u-heading--exlarge u-heading--exlarge-sm mb-3">
                        Web <strong>Solutions</strong>
                      </h1>
                      <p className="fs--17 fs--17-sm">
                        We provide web solutions that are highly customized for client
                        requirements. With our 16+ years of experience, we provide end to
                        end solutions using latest trends, technologies and tools that are
                        well suited for varied level of domains.
                      </p>
                    </div>
                  </ReactWOW>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <ReactWOW animation="slideInLeft">
                        <div className="webSolutions--blocks m-mb-5">
                          <LazyLoadImage
                            effect="blur"
                            alt="Our Services"
                            src={OurServices}
                            className="size--80 size--80-sm"
                          />
                          <h4>Our Services</h4>
                          <p className="mb-2">
                            Our wide range of services includes but not limited to:
                          </p>
                          <ul className="styled-bullet">
                            <li>Bespoke systems - Custom solutions</li>
                            <li>EDM - Email targeting campaigns</li>
                            <li>Designing &amp; Slicing - Pixel perfect results</li>
                            <li>
                              Maintenance &amp; Support - Regular development support
                            </li>
                          </ul>
                        </div>
                      </ReactWOW>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <ReactWOW animation="slideInRight">
                        <div className="webSolutions--blocks">
                          <LazyLoadImage
                            effect="blur"
                            alt="Responsive Websites"
                            src={ResponsiveWebsites}
                            className="size--80 size--80-sm"
                          />
                          <h4>Responsive Websites</h4>
                          <p className="mb-2">
                            View your pixel perfect websites and solutions in any device
                          </p>
                          <ul className="styled-bullet">
                            <li>Desktop</li>
                            <li>Tablet</li>
                            <li>Mobile</li>
                          </ul>
                        </div>
                      </ReactWOW>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionWebSolutions;
