import React from 'react';
// import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';

// Import CSS

// Static Assets
import WebDevelopment from '../assets/images/icons/Web-Development.svg';
import EnterpriseSolutions from '../assets/images/icons/Enterprise-Solutions.svg';
import MobileIntegration from '../assets/images/icons/Mobile-Integration.svg';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

interface SectionServicesProps {}
interface SectionServicesState {}

class SectionServices extends React.Component<
  SectionServicesProps,
  SectionServicesState
> {
  constructor(props: SectionServicesProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="section--services section--services-sm"
          data-bg={'white'}
          id="Services"
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} className="col-centered">
                <Row className="u-flexed u-align-center">
                  <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                    <ReactWOW animation="fadeInLeft">
                      <div className="mb-5">
                        <div className="page-head page-head-sm center u-mb--15">
                          <h3 className="text-grey fs--18 mb-1">What We Do</h3>
                          <h1 className="u-heading--exlarge u-heading--exlarge-sm u-mb--0">
                            Our <strong>Services</strong>
                          </h1>
                          <div className="page-head--divider"></div>
                        </div>
                      </div>
                    </ReactWOW>

                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} className="text-center m-mb-5">
                        <ReactWOW animation="fadeInLeft">
                          <div className="services--block">
                            <figure className="services--icon">
                              <img
                                src={WebDevelopment}
                                alt="Web Development"
                                className="img-fluid"
                              />
                            </figure>
                            <h3>Web Development</h3>
                            <p>Bespoke Applications, CMS, HTML Slicing, Designing, EDM</p>
                            <p>We help to create the best digital web presence.</p>
                          </div>
                        </ReactWOW>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} className="text-center m-mb-5">
                        <ReactWOW animation="fadeInUp">
                          <div className="services--block">
                            <figure className="services--icon">
                              <img
                                src={EnterpriseSolutions}
                                alt="Enterprise Solutions"
                                className="img-fluid"
                              />
                            </figure>
                            <h3>Enterprise Solutions</h3>
                            <p>Much more than just Cloud!</p>
                          </div>
                        </ReactWOW>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} className="text-center m-mb-5">
                        <ReactWOW animation="fadeInRight">
                          <div className="services--block">
                            <figure className="services--icon">
                              <img
                                src={MobileIntegration}
                                alt="Mobile and System Integration"
                                className="img-fluid"
                              />
                            </figure>
                            <h3>Mobile Dev and System Integration</h3>
                            <p>Hybrid, Mobile Applications, AWS, Cloud</p>
                          </div>
                        </ReactWOW>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionServices;
