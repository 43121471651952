import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Static Sections
import SectionHome from '../views/SectionHome';
import SectionServices from '../views/SectionServices';
import SectionWebSolutions from '../views/SectionWebSolutions';
import SectionSystemIntegration from '../views/SectionSystemIntegration';
import SectionEnterpriseSolution from '../views/SectionEnterpriseSolution';
import SectionMissioStatement from '../views/SectionMissioStatement';
import SectionPorfolio from '../views/SectionPortfolio';
import SectionContactUs from '../views/SectionContactUs';
import SectionClients from '../views/SectionClients';
import SectionTechnology from '../views/SectionTechnology';

interface Props extends RouteComponentProps {}

class Home extends React.Component<Props, {}> {
  render() {
    return (
      <>
        <div className="main-page" id="Top">
          {/* Home Section */}
          <SectionHome />

          {/* Services Section */}
          <SectionServices />

          {/* Web Solutions Section */}
          <SectionWebSolutions />

          {/* Enterprise Solutions Section */}
          <SectionEnterpriseSolution />

          {/* System Integration Section */}
          <SectionSystemIntegration />

          {/* Missio Statement Section */}
          <SectionMissioStatement />

          {/* Portfolio Section */}
          <SectionPorfolio />

          {/* Clients Section */}
          <SectionClients />

          {/* Clients Section */}
          <SectionTechnology />

          {/* ContactUs Section */}
          <SectionContactUs />
        </div>
      </>
    );
  }
}

export default Home;
