import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link, animateScroll as scroll } from 'react-scroll';

import { Nav, NavItem } from 'reactstrap';

interface MenuProps extends RouteComponentProps {}

interface MenuState {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
}

class Menu extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      setDropdownOpen: false,
      color: '',
    };
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        <nav className="c-navigation d-none d-lg-flex d-xl-flex ml-auto">
          <Nav className="c-navigation--list">
            <NavItem>
              <Link
                to="Home"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="is--active"
              >
                Home
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="AboutUs"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                activeClass="is--active"
              >
                About Us
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="Services"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                activeClass="is--active"
              >
                Services
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="Portfolio"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                activeClass="is--active"
              >
                Portfolio
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="ContactUs"
                spy={true}
                smooth={true}
                offset={-11}
                duration={500}
                activeClass="is--active"
              >
                Contact Us
              </Link>
            </NavItem>
          </Nav>
        </nav>
      </>
    );
  }
}

export default withRouter(Menu);
