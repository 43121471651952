import React from 'react';
import ReactWOW from 'react-wow';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Static Assets
import IdeaOnRise from '../assets/images/Idea-On-Rise.svg';

interface SectionHomeProps {}
interface SectionHomeState {}

class SectionHome extends React.Component<SectionHomeProps, SectionHomeState> {
  constructor(props: SectionHomeProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section className="section--main section--main-sm" data-bg={'white'} id="Home">
          <Container fluid={false}>
            <Row className="d-flex align-items-center">
              <Col xs={12} md={6} lg={6}>
                <ReactWOW animation="fadeInLeft">
                  <div className="banner--content banner--content-sm">
                    <h1 className="title title-sm u-mb--0">Ideas on Rise</h1>
                    <p className="fs--20 fs--20-sm">
                      We specializing in bridging the gap between the technology and
                      client requriements by providing solutions and right guidance for
                      designing &amp; implementing complex systems.
                    </p>
                    <p className="fs--20 fs--20-sm mt-3">
                      Our aim: Maintaining client satisfaction and timely servicing
                      requests.
                    </p>
                  </div>
                </ReactWOW>
              </Col>
              <Col xs={12} md={5} lg={5} className="ml-auto">
                <LazyLoadImage
                  effect="blur"
                  alt="Idea on Rise"
                  src={IdeaOnRise}
                  className="size--80 size--80-sm"
                />
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionHome;
