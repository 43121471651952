import React from 'react';
import ReactWOW from 'react-wow';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Container, Row, Col, UncontrolledTooltip } from 'reactstrap';

// Import CSS

// Static Assets
import Tech01 from '../assets/images/technology/nodejs.svg';
import Tech02 from '../assets/images/technology/angular.svg';
import Tech03 from '../assets/images/technology/react.svg';
import Tech04 from '../assets/images/technology/laravel.svg';
import Tech05 from '../assets/images/technology/php.svg';
import Tech06 from '../assets/images/technology/sass.svg';
import Tech07 from '../assets/images/technology/html5.svg';
import Tech09 from '../assets/images/technology/jquery.svg';
import Tech10 from '../assets/images/technology/javascript.svg';
import Tech12 from '../assets/images/technology/bootstrap.svg';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

interface SectionTechnologyProps {}
interface SectionTechnologyState {}

class SectionTechnology extends React.Component<
  SectionTechnologyProps,
  SectionTechnologyState
> {
  constructor(props: SectionTechnologyProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section
          className="section--technology section--technology-sm"
          data-bg={'white'}
          id="Technologies"
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} className="col-centered">
                <Row className="u-flexed u-align-center">
                  <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                    <ReactWOW animation="fadeInUp">
                      <div className="mb-5">
                        <div className="page-head page-head-sm center u-mb--15">
                          <h1 className="u-heading--exlarge u-heading--exlarge-sm u-mb--0">
                            Technologies <strong>we use</strong>
                          </h1>
                        </div>
                      </div>
                    </ReactWOW>

                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={11}
                        className="col-centered text-center m-mb-5"
                      >
                        <ReactWOW animation="fadeInDown">
                          <ul className="technology--lists">
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipNode"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Node JS"
                                  src={Tech01}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipNode"
                              >
                                Node JS
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipAngular"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Angular"
                                  src={Tech02}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipAngular"
                              >
                                Angular
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipReact"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="React Js"
                                  src={Tech03}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipReact"
                              >
                                React Js
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipLaravel"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Laravel"
                                  src={Tech04}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipLaravel"
                              >
                                Laravel
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipPHP"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="PHP"
                                  src={Tech05}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipPHP"
                              >
                                PHP
                              </UncontrolledTooltip>
                            </li>

                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipSass"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Sass"
                                  src={Tech06}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipSass"
                              >
                                Sass
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipHTML5"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="HTML5"
                                  src={Tech07}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipHTML5"
                              >
                                HTML5
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipjQuery"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="jQuery"
                                  src={Tech09}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipjQuery"
                              >
                                jQuery
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipJavascript"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Javascript"
                                  src={Tech10}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipJavascript"
                              >
                                Javascript
                              </UncontrolledTooltip>
                            </li>
                            <li className="technology--lists-items">
                              <figure
                                className="image-block-wrapper"
                                id="UncontrolledTooltipBootstrap"
                              >
                                <LazyLoadImage
                                  effect="blur"
                                  alt="Bootstrap"
                                  src={Tech12}
                                  className="img-fluid"
                                />
                              </figure>
                              <UncontrolledTooltip
                                placement="top"
                                target="UncontrolledTooltipBootstrap"
                              >
                                Bootstrap
                              </UncontrolledTooltip>
                            </li>
                          </ul>
                        </ReactWOW>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionTechnology;
