import React from 'react';
// import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-scroll';
import ReactWOW from 'react-wow';

import { Container, Row, Col } from 'reactstrap';

// Import Icons
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import ScrollTopArrow from '../../../components/ScrollTopArrow';

interface FooterProps extends RouteComponentProps {}
interface FooterState {}

class Footer extends React.Component<FooterProps, FooterState> {
  constructor(props: FooterProps) {
    super(props);
    this.state = {};
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <>
        <footer className="c-footer c-footer-sm">
          <Container fluid={false}>
            <Row className="mb-5">
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className="first col--half mobile--mb--25"
              >
                <ReactWOW animation="fadeInLeft">
                  <div className="c-footer--phone">
                    <h4>Phone Number</h4>
                    <p>+61 402 844 393</p>
                  </div>
                </ReactWOW>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className="first col--half mobile--mb--25"
              >
                <ReactWOW animation="fadeInLeft">
                  <div className="c-footer--email">
                    <h4>Email Us</h4>
                    <p>info@ideoris.com.au</p>
                  </div>
                </ReactWOW>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className="first col--half mobile--mb--25"
              >
                <ReactWOW animation="fadeInRight">
                  <div className="c-footer--contact">
                    <h4>Quick Contact</h4>
                    <p>16 Vantage Place, Truganina, Victoria, Melbourne – 3029</p>
                  </div>
                </ReactWOW>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className="first col--half mobile--mb--25"
              >
                <ReactWOW animation="fadeInRight">
                  <div className="c-footer--hours">
                    <h4>Working Hours</h4>
                    <p>Mon to Fri – 9 AM to 6 PM</p>
                    <p>Saturday – 10 AM to 3 PM</p>
                  </div>
                </ReactWOW>
              </Col>
            </Row>

            <Row className="mb-4">
              <ReactWOW animation="fadeInLeft">
                <Col xs={12} sm={12} md={10} lg={9} className="text-center col-centered">
                  <Link to="Home" spy={true} smooth={true} offset={-50} duration={500}>
                    Home
                  </Link>
                  <Link to="AboutUs" spy={true} smooth={true} offset={-80} duration={500}>
                    About Us
                  </Link>
                  <Link
                    to="Services"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={500}
                  >
                    Services
                  </Link>
                  <Link
                    to="Portfolio"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={500}
                  >
                    Portfolio
                  </Link>
                  <Link
                    to="ContactUs"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                </Col>
              </ReactWOW>
            </Row>
            {/* <Row>
              <ReactWOW animation="fadeInRight">
                <Col xs={12} sm={12} md={10} lg={9} className="text-center col-centered">
                  <ul className="c-footer--social c-footer--social-sm">
                    <li>
                      <a href="/">
                        <FacebookIcon fontSize="large" />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <InstagramIcon fontSize="large" />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <TwitterIcon fontSize="large" />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <YouTubeIcon fontSize="large" />
                      </a>
                    </li>
                  </ul>
                </Col>
              </ReactWOW>
            </Row> */}
          </Container>

          <div className="c-footer--bottom">
            <Container fluid={false}>
              <Row>
                <ReactWOW animation="fadeInLeft">
                  <Col
                    xs={12}
                    md={8}
                    lg={8}
                    className="first m-text-center mobile--mb--25"
                  >
                    Copyright © All rights reserved 2020
                  </Col>
                </ReactWOW>
                <ReactWOW animation="fadeInRight">
                  <Col
                    xs={12}
                    md={3}
                    lg={3}
                    className="text-left m-text-center ml-auto last"
                  >
                    A Melbourne based Web company
                  </Col>
                </ReactWOW>
              </Row>
            </Container>
          </div>
        </footer>

        <ScrollTopArrow />
      </>
    );
  }
}

export default withRouter(Footer);
